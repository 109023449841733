<template>
  <div>  
    <v-app-bar app >   
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="drawer = !drawer" ></v-app-bar-nav-icon>
      <v-icon x-large >mdi-library</v-icon>
      <v-toolbar-title >
        Easy2Reading
      </v-toolbar-title>
      <v-btn  @click="install" text v-if="deferredPrompt" color="info"  dark plain right >
        <v-col>
          <v-icon>mdi-cellphone-arrow-down</v-icon>
          <div>Install</div>
        </v-col>        
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn-toggle v-if="$vuetify.breakpoint.mdAndUp" color="primary" group>
        <v-btn v-for="item in this.$store.state.items"
          :key="item.title"
          link
          :to="item.route"
          class="ma-1 "
          depressed
          >
        <v-icon>{{item.icon}}</v-icon>
        {{item.title}}
        
      </v-btn>
      </v-btn-toggle>
    </v-app-bar>
    
    
  </div>  
</template>
<script>
export default {
    data() {
          return {
            deferredPrompt: null, 
          };
        },
    created() {
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        this.$analytics.logEvent('not installed');           
      });
      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
        this.$analytics.logEvent('installed');           
      });
    },
    methods: {
      async dismiss() {
        this.deferredPrompt = null;
      },
      async install() {
        this.deferredPrompt.prompt();
        this.$analytics.logEvent('install');

      },     
    },    
    computed: {
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (value) {
          this.$store.commit('updateDrawer', value)
        }
      }
    },
}
</script>