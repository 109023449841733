<template>

  <v-app id="inspire">
    <nd></nd>
    <ab></ab> 
    
    <v-main>
      <!--  -->
      <v-container fluid class="pa-5 offset-12 col-md-8 col-lg-6">
        <router-view></router-view>
        
       
        
        

       
      
      </v-container> 
      <!--  -->
    
    </v-main>
    <ftr v-if='$vuetify.breakpoint.smAndUp'></ftr>
  </v-app>
</template>

<script>
  import ab from './components/AppBar.vue'
  import nd from './components/NavigationDrawer.vue'
  import ftr from './components/Footer'
  
  export default {
    name: 'App',    
    components: {
      ab,
      nd,
      ftr,
      
    },
    
    
  };
</script>