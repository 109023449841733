<template>
<v-card class="rounded" > 
<div  >
    <v-card height="80vh" class="mx-auto">
        <v-card-title>{{data.heading.title}}</v-card-title>
        <v-card-subtitle>{{data.heading.subtitle}}</v-card-subtitle>
        <v-card-text>
            <template>
              <v-simple-table class="mx-auto" justify-center align-center>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Voice
                      </th>
                      <th class="text-left">
                        Sample
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in data.audiolist"
                      :key="item.voice"
                    >
                      <td>{{ item.voice }}</td>
                      <td><audio :src="item.sample" controls type="audio/mpeg" ></audio></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>   

        </v-card-text>
    </v-card>
    <v-divider ></v-divider>
    <v-card height="10vh">
        <v-card-title>{{data.credits.title}}</v-card-title>
        <v-card-text>{{data.credits.text}}</v-card-text>
    </v-card>
        
</div>
</v-card>
</template>
<script>
export default {
    data(){
        return{
            data:this.$store.state.about,
        }
    }
}
</script>