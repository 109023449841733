<template>
<div>
    <v-card class="rounded"> 
    <div>
        <v-card  v-if="$vuetify.breakpoint.mdAndUp">
            <v-card-title><h1>{{header.title}}</h1></v-card-title>
            <v-divider ></v-divider>
            <v-card-subtitle><h2>{{header.subtitle}}</h2></v-card-subtitle>
            <v-card-text class="black--text">{{header.text}}</v-card-text>
        </v-card>

       <tts></tts>
    </div> 
    </v-card>
    <v-divider vertical ></v-divider>
    <About></About>
    <v-divider vertical></v-divider>
    <Privacy></Privacy>
    <v-divider vertical></v-divider>
    <Contact></Contact>
    <v-divider vertical></v-divider>
</div>
</template>
<script>
import tts from '../components/tts'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'
export default {
    data(){
        return {
            header:this.$store.state.headers
            }
    },
    components: {
        tts,
        About,
        Contact,
        Privacy,
    },
}
</script>