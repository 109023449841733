import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
/*import About from '../views/About.vue'
import Privacy from '../views/Privacy.vue'
import Contact from '../views/Contact.vue'*/
//import goTo from 'vuetify/lib/services/goto'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Free online Text To Speech TTS with 200+ Natural Voices - Easy2Reading' }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { title: 'About - Easy2Reading' }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
    meta: { title: 'Privacy - Easy2Reading' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: { title: 'Contact - Easy2Reading' }
  }
  //{
    //path: '/about',
    //name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
 // }
]



const router = new VueRouter({
  mode:'history',
  base: process.env.BASE_URL,
  routes,
  

  /*scrollBehavior (to, from, savedPosition)  {  
  let scrollTo = 0;
  if(to.hash) {
    scrollTo = to.hash
  }
  else if(savedPosition) {
    scrollTo = savedPosition.y
  }  
  return goTo(scrollTo)
  }   */
  
  })

export default router
