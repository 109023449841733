<template>
  <v-footer
    color="primary lighten-0"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="item in this.$store.state.items"
        :key="item.title"
        color="primary"
        link
        :to='item.route'
        class="my-2 white--text"
        depressed
    
      >
        {{ item.title }}
      </v-btn>
      <v-col
        class="primary  py-4 text-center white--text"
        cols="12"
      >
        Easy2Reading © {{ new Date().getFullYear() }} 
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      links: [
        'Home',
        'About Us',
        'Team',
        'Services',
        'Blog',
        'Contact Us',
      ],
    }),
  }
</script>