import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    headers: {title:'Easy 2 Reading',subtitle:'Free online Text To Speech service',text:'Easy2Reading.com is a free online text to speech converter. It has over 200+ voices with more than 15 languages. It generates mp3 audio files give sounds like natural voices. This service is free and you are allowed to use the speech files for any purpose, including commercial uses.'},
    drawer: false,
    items: [
      { title: 'Home', icon: 'mdi-home',route:'/' },
      { title: 'About', icon: 'mdi-library',route:'about' },
      { title: 'Privacy', icon: 'mdi-shield-lock',route:'privacy' },
      { title: 'contact', icon: 'mdi-account-box',route:'contact' },
    ],
    speed_modes:[
      {value: 1.00,mode:'Normal'},
      {value: 0.75,mode:'Slow'},
      {value: 0.50,mode:'Slower'},
      {value: 1.50,mode:'Fast'},
      {value: 2.00,mode:'Faster'},
    ],
    textrules:[
      v => !!v || 'Text is required',
      v => (v && v.length <= 5000) || 'Name must be less than 5000 characters',
    ],
    speedrules:[v => !!v || 'Item is required'],
    nameRules: [
      v => !!v || 'Name of audio file is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    about:{
      heading:{title:'About this service',subtitle:'Supported voices:'},
      audiolist:[
        {voice:'English - US Female', sample:'/audio/USFemale.mp3'},        
        {voice:'English - US Male',   sample:'/audio/USMale.mp3'},
        {voice:'English - UK Female', sample:'/audio/UKFemale.mp3'},
        {voice:'English - UK Male',   sample:'/audio/UKMale.mp3'},
      ],
      credits:{title:'Credits:',text:'This tool uses the Google Cloud Text to Speech to generate audio files from text.'},
    },
    privacy:[
      {
      title:'Privacy Policy',
      list:[
        {heading:'Consent',text:'By using our website, you hereby consent to our Privacy Policy and agree to its terms. '},
        {heading:'Our Advertising Partners',text:'Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access,we hyperlinked to their privacy policies below.'},
        {heading:'Advertising Partners Privacy Policies',text:'You may consult this list to find the Privacy Policy for each of the advertising partners of easy2reading.com.Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on easy2reading.com, which are sent directly to users browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.Note that easy2reading.com has no access to or control over these cookies that are used by third-party advertisers.'},
        {heading:'Third-Party Privacy Policies',text:'Easy2Reading Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers respective websites.'},
        {heading:'Storage of User Files and generated Data',text:'All submitted files or inputs, as well as the converted outputs, are deleted automatically after a day. No backups of those files are made and only admin of this website has able to access your files. The files are kept for a day with the sole purpose of giving you enough time to download them. We do not look at them or mine any data from them.'},
        {heading:'Log Files',text:'easy2reading.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users movement on the website, and gathering demographic information.'},
        {heading:'Cookies and Web Beacons',text:'Like any other website, easy2reading.com uses cookies. These cookies are used to store information including visitors preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users experience by customizing our web page content based on visitors browser type and/or other information.For more general information on cookies, please read '},
        {heading:'Childrens Information',text:'Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.easy2reading.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.'},

      ],

    },
    {
      title:'Terms of services',
      list:[
        {heading:'Responsiblities for any Damages or Troubles',text:'We are working hard to ensure that the services we offer are free of errors but this cannot be guaranteed. As a user of this service, you agree that Easy 2 Reading will not be held responsible for any failure or error.'},
        {heading:'Cookies',text:'We employ the use of cookies. By accessing easy2reading.com, you agreed to use cookies in agreement with the easy2reading.com Privacy Policy.Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.'},
        {heading:'Copyright Issues',text:'Do not use our services and intellectual property for any form of infringement.'},
      
      ],

    },
  ],
    contact:'https://docs.google.com/forms/d/e/1FAIpQLSc0lx4jHbj3s6lV257uIN_nVQm2L8g9DWY_C9RXiKk_9msDQg/viewform?embedded=true',
    voices:[
      {No:"118",Name:"es-ES-Standard-B", Supported_language:"es-ES", SSML_Voice_Gender:"MALE"},
      {No:"119",Name:"es-ES-Standard-A", Supported_language:"es-ES", SSML_Voice_Gender:"FEMALE"},
      {No:"120",Name:"en-US-Standard-B", Supported_language:"en-US", SSML_Voice_Gender:"MALE"},
      {No:"121",Name:"en-US-Standard-C", Supported_language:"en-US", SSML_Voice_Gender:"FEMALE"},
      {No:"122",Name:"en-US-Standard-D", Supported_language:"en-US", SSML_Voice_Gender:"MALE"},
      {No:"123",Name:"en-US-Standard-E", Supported_language:"en-US", SSML_Voice_Gender:"FEMALE"},
      {No:"124",Name:"en-US-Standard-G", Supported_language:"en-US", SSML_Voice_Gender:"MALE"},
      {No:"125",Name:"en-US-Standard-H", Supported_language:"en-US", SSML_Voice_Gender:"FEMALE"},
      {No:"126",Name:"en-US-Standard-I", Supported_language:"en-US", SSML_Voice_Gender:"FEMALE"},
      {No:"127",Name:"en-US-Standard-J", Supported_language:"en-US", SSML_Voice_Gender:"MALE"},
      {No:"128",Name:"ar-XA-Standard-A", Supported_language:"ar-XA", SSML_Voice_Gender:"FEMALE"},
      {No:"129",Name:"ar-XA-Standard-B", Supported_language:"ar-XA", SSML_Voice_Gender:"MALE"},
      {No:"130",Name:"ar-XA-Standard-C", Supported_language:"ar-XA", SSML_Voice_Gender:"MALE"},
      {No:"131",Name:"ar-XA-Standard-D", Supported_language:"ar-XA", SSML_Voice_Gender:"FEMALE"},
      {No:"132",Name:"fr-FR-Standard-E", Supported_language:"fr-FR", SSML_Voice_Gender:"FEMALE"},
      {No:"133",Name:"it-IT-Standard-A", Supported_language:"it-IT", SSML_Voice_Gender:"FEMALE"},
      {No:"134",Name:"ru-RU-Standard-E", Supported_language:"ru-RU", SSML_Voice_Gender:"FEMALE"},
      {No:"135",Name:"ru-RU-Standard-A", Supported_language:"ru-RU", SSML_Voice_Gender:"FEMALE"},
      {No:"136",Name:"ru-RU-Standard-B", Supported_language:"ru-RU", SSML_Voice_Gender:"MALE"},
      {No:"137",Name:"ru-RU-Standard-C", Supported_language:"ru-RU", SSML_Voice_Gender:"FEMALE"},
      {No:"138",Name:"ru-RU-Standard-D", Supported_language:"ru-RU", SSML_Voice_Gender:"MALE"},
      {No:"139",Name:"cmn-CN-Standard-D",Supported_language:"cmn-CN",SSML_Voice_Gender:"FEMALE"},
      {No:"140",Name:"cmn-CN-Standard-C",Supported_language:"cmn-CN",SSML_Voice_Gender:"MALE"},
      {No:"141",Name:"cmn-CN-Standard-B",Supported_language:"cmn-CN",SSML_Voice_Gender:"MALE"},
      {No:"142",Name:"cmn-CN-Standard-A",Supported_language:"cmn-CN",SSML_Voice_Gender:"FEMALE"},
      {No:"143",Name:"ja-JP-Standard-A", Supported_language:"ja-JP", SSML_Voice_Gender:"FEMALE"},
      {No:"144",Name:"ja-JP-Standard-C", Supported_language:"ja-JP", SSML_Voice_Gender:"MALE"},
      {No:"145",Name:"ja-JP-Standard-B", Supported_language:"ja-JP", SSML_Voice_Gender:"FEMALE"},
      {No:"146",Name:"ja-JP-Standard-D", Supported_language:"ja-JP", SSML_Voice_Gender:"MALE"},
      {No:"147",Name:"cmn-TW-Standard-A",Supported_language:"cmn-TW",SSML_Voice_Gender:"FEMALE"},
      {No:"148",Name:"cmn-TW-Standard-B",Supported_language:"cmn-TW",SSML_Voice_Gender:"MALE"},
      {No:"149",Name:"cmn-TW-Standard-C",Supported_language:"cmn-TW",SSML_Voice_Gender:"MALE"},
      {No:"150",Name:"ko-KR-Standard-B", Supported_language:"ko-KR", SSML_Voice_Gender:"FEMALE"},
      {No:"151",Name:"ko-KR-Standard-C", Supported_language:"ko-KR", SSML_Voice_Gender:"MALE"},
      {No:"152",Name:"ko-KR-Standard-D", Supported_language:"ko-KR", SSML_Voice_Gender:"MALE"},
      {No:"153",Name:"ko-KR-Standard-A", Supported_language:"ko-KR", SSML_Voice_Gender:"FEMALE"},
      {No:"154",Name:"vi-VN-Standard-A", Supported_language:"vi-VN", SSML_Voice_Gender:"FEMALE"},
      {No:"155",Name:"vi-VN-Standard-B", Supported_language:"vi-VN", SSML_Voice_Gender:"MALE"},
      {No:"156",Name:"vi-VN-Standard-C", Supported_language:"vi-VN", SSML_Voice_Gender:"FEMALE"},
      {No:"157",Name:"vi-VN-Standard-D", Supported_language:"vi-VN", SSML_Voice_Gender:"MALE"},
      {No:"158",Name:"fil-PH-Standard-A",Supported_language:"fil-PH",SSML_Voice_Gender:"FEMALE"},
      {No:"159",Name:"id-ID-Standard-D", Supported_language:"id-ID", SSML_Voice_Gender:"FEMALE"},
      {No:"160",Name:"nl-NL-Standard-A", Supported_language:"nl-NL", SSML_Voice_Gender:"FEMALE"},
      {No:"161",Name:"id-ID-Standard-A", Supported_language:"id-ID", SSML_Voice_Gender:"FEMALE"},
      {No:"162",Name:"id-ID-Standard-B", Supported_language:"id-ID", SSML_Voice_Gender:"MALE"},
      {No:"163",Name:"id-ID-Standard-C", Supported_language:"id-ID", SSML_Voice_Gender:"MALE"},
      {No:"164",Name:"nl-NL-Standard-B", Supported_language:"nl-NL", SSML_Voice_Gender:"MALE"},
      {No:"165",Name:"nl-NL-Standard-C", Supported_language:"nl-NL", SSML_Voice_Gender:"MALE"},
      {No:"166",Name:"nl-NL-Standard-D", Supported_language:"nl-NL", SSML_Voice_Gender:"FEMALE"},
      {No:"167",Name:"nl-NL-Standard-E", Supported_language:"nl-NL", SSML_Voice_Gender:"FEMALE"},
      {No:"168",Name:"fil-PH-Standard-B",Supported_language:"fil-PH",SSML_Voice_Gender:"FEMALE"},
      {No:"169",Name:"fil-PH-Standard-C",Supported_language:"fil-PH",SSML_Voice_Gender:"MALE"},
      {No:"170",Name:"fil-PH-Standard-D",Supported_language:"fil-PH",SSML_Voice_Gender:"MALE"},
      {No:"171",Name:"yue-HK-Standard-A",Supported_language:"yue-HK",SSML_Voice_Gender:"FEMALE"},
      {No:"172",Name:"yue-HK-Standard-B",Supported_language:"yue-HK",SSML_Voice_Gender:"MALE"},
      {No:"173",Name:"yue-HK-Standard-C",Supported_language:"yue-HK",SSML_Voice_Gender:"FEMALE"},
      {No:"174",Name:"yue-HK-Standard-D",Supported_language:"yue-HK",SSML_Voice_Gender:"MALE"},
      {No:"175",Name:"cs-CZ-Standard-A", Supported_language:"cs-CZ", SSML_Voice_Gender:"FEMALE"},
      {No:"176",Name:"el-GR-Standard-A", Supported_language:"el-GR", SSML_Voice_Gender:"FEMALE"},
      {No:"177",Name:"pt-BR-Standard-A", Supported_language:"pt-BR", SSML_Voice_Gender:"FEMALE"},
      {No:"178",Name:"hu-HU-Standard-A", Supported_language:"hu-HU", SSML_Voice_Gender:"FEMALE"},
      {No:"179",Name:"pl-PL-Standard-E", Supported_language:"pl-PL", SSML_Voice_Gender:"FEMALE"},
      {No:"180",Name:"sk-SK-Standard-A", Supported_language:"sk-SK", SSML_Voice_Gender:"FEMALE"},
      {No:"181",Name:"uk-UA-Standard-A", Supported_language:"uk-UA", SSML_Voice_Gender:"FEMALE"},
      {No:"182",Name:"pl-PL-Standard-A", Supported_language:"pl-PL", SSML_Voice_Gender:"FEMALE"},
      {No:"183",Name:"pl-PL-Standard-B", Supported_language:"pl-PL", SSML_Voice_Gender:"MALE"},
      {No:"184",Name:"pl-PL-Standard-C", Supported_language:"pl-PL", SSML_Voice_Gender:"MALE"},
      {No:"185",Name:"pl-PL-Standard-D", Supported_language:"pl-PL", SSML_Voice_Gender:"FEMALE"},
      {No:"186",Name:"tr-TR-Standard-B", Supported_language:"tr-TR", SSML_Voice_Gender:"MALE"},
      {No:"187",Name:"tr-TR-Standard-C", Supported_language:"tr-TR", SSML_Voice_Gender:"FEMALE"},
      {No:"188",Name:"tr-TR-Standard-D", Supported_language:"tr-TR", SSML_Voice_Gender:"FEMALE"},
      {No:"189",Name:"tr-TR-Standard-E", Supported_language:"tr-TR", SSML_Voice_Gender:"MALE"},
      {No:"190",Name:"tr-TR-Standard-A", Supported_language:"tr-TR", SSML_Voice_Gender:"FEMALE"},
      {No:"191",Name:"th-TH-Standard-A", Supported_language:"th-TH", SSML_Voice_Gender:"FEMALE"},
      {No:"192",Name:"bn-IN-Standard-A", Supported_language:"bn-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"193",Name:"bn-IN-Standard-B", Supported_language:"bn-IN", SSML_Voice_Gender:"MALE"},
      {No:"194",Name:"en-IN-Standard-D", Supported_language:"en-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"195",Name:"en-IN-Standard-A", Supported_language:"en-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"196",Name:"en-IN-Standard-B", Supported_language:"en-IN", SSML_Voice_Gender:"MALE"},
      {No:"197",Name:"en-IN-Standard-C", Supported_language:"en-IN", SSML_Voice_Gender:"MALE"},
      {No:"198",Name:"gu-IN-Standard-A", Supported_language:"gu-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"199",Name:"gu-IN-Standard-B", Supported_language:"gu-IN", SSML_Voice_Gender:"MALE"},
      {No:"200",Name:"hi-IN-Standard-D", Supported_language:"hi-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"201",Name:"hi-IN-Standard-A", Supported_language:"hi-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"202",Name:"hi-IN-Standard-B", Supported_language:"hi-IN", SSML_Voice_Gender:"MALE"},
      {No:"203",Name:"hi-IN-Standard-C", Supported_language:"hi-IN", SSML_Voice_Gender:"MALE"},
      {No:"204",Name:"kn-IN-Standard-A", Supported_language:"kn-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"205",Name:"kn-IN-Standard-B", Supported_language:"kn-IN", SSML_Voice_Gender:"MALE"},
      {No:"206",Name:"ml-IN-Standard-A", Supported_language:"ml-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"207",Name:"ml-IN-Standard-B", Supported_language:"ml-IN", SSML_Voice_Gender:"MALE"},
      {No:"208",Name:"ta-IN-Standard-A", Supported_language:"ta-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"209",Name:"ta-IN-Standard-B", Supported_language:"ta-IN", SSML_Voice_Gender:"MALE"},
      {No:"210",Name:"te-IN-Standard-A", Supported_language:"te-IN", SSML_Voice_Gender:"FEMALE"},
      {No:"211",Name:"te-IN-Standard-B", Supported_language:"te-IN", SSML_Voice_Gender:"MALE"},
      {No:"212",Name:"da-DK-Standard-A", Supported_language:"da-DK", SSML_Voice_Gender:"FEMALE"},
      {No:"213",Name:"da-DK-Standard-C", Supported_language:"da-DK", SSML_Voice_Gender:"MALE"},
      {No:"214",Name:"da-DK-Standard-D", Supported_language:"da-DK", SSML_Voice_Gender:"FEMALE"},
      {No:"215",Name:"da-DK-Standard-E", Supported_language:"da-DK", SSML_Voice_Gender:"FEMALE"},
      {No:"216",Name:"fi-FI-Standard-A", Supported_language:"fi-FI", SSML_Voice_Gender:"FEMALE"},
      {No:"217",Name:"sv-SE-Standard-A", Supported_language:"sv-SE", SSML_Voice_Gender:"FEMALE"},
      {No:"218",Name:"nb-NO-Standard-A", Supported_language:"nb-NO", SSML_Voice_Gender:"FEMALE"},
      {No:"219",Name:"nb-NO-Standard-B", Supported_language:"nb-NO", SSML_Voice_Gender:"MALE"},
      {No:"220",Name:"nb-NO-Standard-C", Supported_language:"nb-NO", SSML_Voice_Gender:"FEMALE"},
      {No:"221",Name:"nb-NO-Standard-D", Supported_language:"nb-NO", SSML_Voice_Gender:"MALE"},
      {No:"222",Name:"nb-no-Standard-E", Supported_language:"nb-NO", SSML_Voice_Gender:"FEMALE"},
      {No:"223",Name:"nb-no-Standard-E", Supported_language:"nb-NO", SSML_Voice_Gender:"FEMALE"},
      {No:"224",Name:"pt-PT-Standard-A", Supported_language:"pt-PT", SSML_Voice_Gender:"FEMALE"},
      {No:"225",Name:"pt-PT-Standard-B", Supported_language:"pt-PT", SSML_Voice_Gender:"MALE"},
      {No:"226",Name:"pt-PT-Standard-C", Supported_language:"pt-PT", SSML_Voice_Gender:"MALE"},
      {No:"227",Name:"pt-PT-Standard-D", Supported_language:"pt-PT", SSML_Voice_Gender:"FEMALE"},
      {No:"228",Name:"fr-FR-Standard-A", Supported_language:"fr-FR", SSML_Voice_Gender:"FEMALE"},
      {No:"229",Name:"fr-FR-Standard-B", Supported_language:"fr-FR", SSML_Voice_Gender:"MALE"},
      {No:"230",Name:"fr-FR-Standard-C", Supported_language:"fr-FR", SSML_Voice_Gender:"FEMALE"},
      {No:"231",Name:"fr-FR-Standard-D", Supported_language:"fr-FR", SSML_Voice_Gender:"MALE"},
      {No:"232",Name:"de-DE-Standard-E", Supported_language:"de-DE", SSML_Voice_Gender:"MALE"},
      {No:"233",Name:"de-DE-Standard-A", Supported_language:"de-DE", SSML_Voice_Gender:"FEMALE"},
      {No:"234",Name:"de-DE-Standard-B", Supported_language:"de-DE", SSML_Voice_Gender:"MALE"},
      {No:"235",Name:"de-DE-Standard-F", Supported_language:"de-DE", SSML_Voice_Gender:"FEMALE"},
      {No:"236",Name:"fr-CA-Standard-A", Supported_language:"fr-CA", SSML_Voice_Gender:"FEMALE"},
      {No:"237",Name:"fr-CA-Standard-B", Supported_language:"fr-CA", SSML_Voice_Gender:"MALE"},
      {No:"238",Name:"fr-CA-Standard-C", Supported_language:"fr-CA", SSML_Voice_Gender:"FEMALE"},
      {No:"239",Name:"fr-CA-Standard-D", Supported_language:"fr-CA", SSML_Voice_Gender:"MALE"},
      {No:"240",Name:"it-IT-Standard-B", Supported_language:"it-IT", SSML_Voice_Gender:"FEMALE"},
      {No:"241",Name:"it-IT-Standard-C", Supported_language:"it-IT", SSML_Voice_Gender:"MALE"},
      {No:"242",Name:"it-IT-Standard-D", Supported_language:"it-IT", SSML_Voice_Gender:"MALE"},
      {No:"243",Name:"en-AU-Standard-A", Supported_language:"en-AU", SSML_Voice_Gender:"FEMALE"},
      {No:"244",Name:"en-AU-Standard-B", Supported_language:"en-AU", SSML_Voice_Gender:"MALE"},
      {No:"245",Name:"en-AU-Standard-C", Supported_language:"en-AU", SSML_Voice_Gender:"FEMALE"},
      {No:"246",Name:"en-AU-Standard-D", Supported_language:"en-AU", SSML_Voice_Gender:"MALE"},
      {No:"247",Name:"en-GB-Standard-A", Supported_language:"en-GB", SSML_Voice_Gender:"FEMALE"},
      {No:"248",Name:"en-GB-Standard-B", Supported_language:"en-GB", SSML_Voice_Gender:"MALE"},
      {No:"249",Name:"en-GB-Standard-C", Supported_language:"en-GB", SSML_Voice_Gender:"FEMALE"},
      {No:"250",Name:"en-GB-Standard-D", Supported_language:"en-GB", SSML_Voice_Gender:"MALE"},
      {No:"251",Name:"en-GB-Standard-F", Supported_language:"en-GB", SSML_Voice_Gender:"FEMALE"}],
    device:[
      { value:'handset-class-device',                   name:'Default'},                      
      { value:'wearable-class-device',                  name:'Smart watch'},
      { value: 'handset-class-device',                  name:'Smartphone'},      
      { value:'headphone-class-device',                 name:'Headphones/earbuds'},      
      { value:'small-bluetooth-speaker-class-device',   name:'Small home speaker'},        
      { value:'medium-bluetooth-speaker-class-device',  name:'Smart home speaker'},          
      { value:'large-home-entertainment-class-device',  name:'Smart TV'},          
      { value:'large-automotive-class-device',          name:'Car speaker'},  
      { value:'telephony-class-application',            name:'IVR system'},
      
    ] ,
    audio_format:[
      { value:'MP3',name:'.MP3'},
      { value:'OGG',name:'.OGG'},

    ],
    volume:[
      { value:0.0,name:'Normal'},
      { value:-2.0,name:'Low'},
      { value:-4.0,name:'Lower'},
      { value:2.0,name:'High'},
      { value:4.0,name:'Higher'},

    ],
    pitch:[
      { value:0.0,name:'Normal'},
      { value:-5.0,name:'Low'},
      { value:-10.0,name:'Lower'},
      { value:5.0,name:'High'},
      { value:10.0,name:'Higher'},

    ],
    
  },
  mutations: {
    updateDrawer (state, value) {
      state.drawer = value
    },
   
 
  },
  getters:{
    list_of_voices: (state) =>(language,gender)=> {
      return state.voices.filter(voice=> voice.Supported_language === language&&voice.SSML_Voice_Gender===gender)
    },
  },
  modules: {
  }
})
