<template>
<v-card height="95vh" class="rounded">
<v-row justify="center">
                <iframe   src="https://docs.google.com/forms/d/e/1FAIpQLSc0lx4jHbj3s6lV257uIN_nVQm2L8g9DWY_C9RXiKk_9msDQg/viewform?embedded=true" width="640" height="710" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

</v-row>
</v-card>
</template>
<script>
export default {
    
}
</script>